import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/kunden-registration',
    name: 'customer-registrations.submit',
    component: () => import('./pages/CustomerRegistrationsSubmit.vue'),
    meta: { auth: 'optional' },
  },
  {
    path: 'forms/customer-registration',
    name: 'customer-registrations.index',
    component: () => import('./pages/CustomerRegistrationsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/saison-anmeldung',
    name: 'season-applications.submit',
    component: () => import('./pages/SeasonApplicationsSubmit.vue'),
    meta: { auth: 'optional' },
  },
  {
    path: '/personalien-arbeitnehmer',
    name: 'employee-registrations.submit',
    component: () => import('./pages/EmployeeRegistrationsSubmit.vue'),
  },
  {
    path: '/zahlungsverbindung-fremdes-konto',
    name: 'foreign-payment-connections.submit',
    component: () => import('./pages/PaymentConnectionsSubmit.vue'),
    props: { type: 'foreign' },
  },
  {
    path: '/zahlungsverbindung',
    name: 'payment-connections.submit',
    component: () => import('./pages/PaymentConnectionsSubmit.vue'),
    props: { type: 'self' },
  },
  {
    path: '/settings/forms',
    name: 'settings.forms',
    component: () => import('./pages/FormSettings.vue'),
    meta: { auth: true },
  },
  {
    path: '/forms/season-application',
    name: 'season-applications.index',
    component: () => import('./pages/SeasonApplicationsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/forms/employee-registration',
    name: 'employee-registrations.index',
    component: () => import('./pages/EmployeeRegistrationsIndex.vue'),
    meta: { auth: true },
  },

  {
    path: '/forms/foreign-payment-connection',
    name: 'foreign-payment-connections.index',
    component: () => import('./pages/PaymentConnectionsIndex.vue'),
    meta: { auth: true },
    props: {
      type: 'foreign',
    },
  },

  {
    path: '/forms/payment-connection',
    name: 'payment-connections.index',
    component: () => import('./pages/PaymentConnectionsIndex.vue'),
    meta: { auth: true },
    props: {
      type: 'self',
    },
  },
  {
    path: '/forms/employee-season-papers',
    name: 'employee-season-papers.index',
    component: () => import('./pages/EmployeeSeasonPapersIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/personalienblatt-arbeitnehmer',
    name: 'employee-season-papers.submit',
    component: () => import('./pages/EmployeeSeasonPapersSubmit.vue'),
    meta: { auth: true },
  },

  {
    path: '/forms/runsheet/:slug',
    name: 'runsheets.index',
    component: () => import('./pages/RunSheetsIndex.vue'),
    meta: { auth: true },
    props: (route) => ({ slug: route.params.slug }),
  },
  {
    path: '/forms/runsheet/:slug/submit',
    name: 'runsheets.submit',
    component: () => import('./pages/RunSheetsSubmit.vue'),
    meta: { auth: true },
    props: (route) => ({ slug: route.params.slug }),
  },
  {
    path: '/forms/runsheet/edit/:id',
    name: 'runsheets.edit',
    component: () => import('./pages/RunSheetsEdit.vue'),
    meta: { auth: true },
    props: (route) => ({ id: route.params.id }),
  },

  {
    path: '/settings/run-sheet-templates',
    name: 'run-sheet-templates.index',
    component: () => import('./pages/RunSheetTemplatesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/run-sheet-templates/create',
    name: 'run-sheet-templates.create',
    component: () => import('./pages/RunSheetTemplatesCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/run-sheet-templates/edit/:id',
    name: 'run-sheet-templates.edit',
    component: () => import('./pages/RunSheetTemplatesEdit.vue'),
    meta: { auth: true },
  },
]
