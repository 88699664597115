import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/store/loading'

export const useRoleStore = defineStore('roles', {
  state: () => ({
    roles: [],
  }),
  actions: {
    setRoles(roles) {
      this.roles = roles
    },
    removeRole(roleId) {
      const role = this.roles.find((r) => r.id === roleId)
      const index = this.roles.indexOf(role)
      this.roles.splice(index, 1)
    },
    addRole(role) {
      this.roles.push(role)
    },

    fetchRoles() {
      return new Promise((resolve, reject) => {
        if (this.roles.length === 0) {
          useLoadingStore().loading({ roles: true })
          axios
            .get('/roles')
            .then((response) => {
              this.setRoles(response.data)
              resolve(this.roles)
            })
            .catch((error) => {
              reject(error)
            })
            .finally(() => {
              useLoadingStore().loading({ roles: false })
            })
        } else {
          resolve(this.roles)
        }
      })
    },
    updateRole(role) {
      return new Promise((resolve, reject) => {
        axios
          .put(`roles/${role.id}`, role)
          .then(() => {
            const oldRole = this.roles.find((r) => r.id === role.id)
            const index = this.roles.indexOf(oldRole)
            this.roles[index] = role
            resolve(role)
          })
          .catch((error) => reject(error))
      })
    },
    deleteRole(roleId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`roles/${roleId}`)
          .then(() => {
            this.removeRole(roleId)
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
  },
})
