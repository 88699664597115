import { getRunSheetTemplates } from '@/features/forms/services/run-sheet-service'
import { defineStore } from 'pinia'

export const useRunSheetStore = defineStore('runSheet', {
  state: () => ({
    templates: [],
  }),
  actions: {
    async fetchRunSheetTemplates() {
      this.templates = await getRunSheetTemplates()

      return this.templates
    },
  },
})
