import axios from 'axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/store/loading'
import { useDefaultStore } from '@/store/default'

export const useWarningStore = defineStore('warning', {
  state: () => ({
    warningCategories: [],
  }),
  actions: {
    async fetchWarningCategories() {
      useLoadingStore().loading({
        warningCategories: true,
      })
      try {
        const { data } = await axios.$get('/warning-categories')

        this.warningCategories = data
      } catch (_e) {
        useDefaultStore().error('Fehler beim Laden der Kategorien.')
      }
      useLoadingStore().loading({
        warningCategories: false,
      })
      return this.warningCategories
    },
  },
})
