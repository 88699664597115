<template>
  <component
    :is="clientValidation ? VForm : 'form'"
    ref="formElement"
    @submit.prevent="onSubmit"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { provide } from 'vue'
import type { Form } from 'laravel-precognition-vue/dist/types'
import VForm from '@/features/shared/components/VForm.vue'

const props = defineProps<{
  form: Form<any>
  clientValidation?: boolean
}>()

const formElement = ref<HTMLElement | InstanceType<typeof VForm> | null>(null)
provide('form', props.form)

const emit = defineEmits(['submit'])

defineExpose({
  validate,
})

const onSubmit = (event) => emit('submit', event)

function validate() {
  if (!props.clientValidation) {
    throw new Error(
      'You can only use the validate method when clientValidation is enabled'
    )
  }
  return (formElement.value as InstanceType<typeof VForm>).validate()
}
</script>
