import axios from '@/plugins/axios'
import { confirmAction } from '@/utils'
import { useI18n } from 'vue-i18n'

let version: null | string = null

export function useDetectNewVersion() {
  const { t } = useI18n()

  function fetchVersion() {
    axios.$get('/version').then((response) => {
      if (!version) {
        version = response.version
        return
      }

      if (version === response.version) {
        return
      }

      confirmAction({
        title: t('general.update-available.title'),
        text: t('general.update-available.text'),
        confirmButtonText: t('general.update-available.button'),
        showCancelButton: false,
      }).then((value) => {
        if (!value) return

        location.reload()
      })
    })
  }

  let interval: number | null = null

  onMounted(() => {
    fetchVersion()
    interval = setInterval(fetchVersion, 1000 * 60) // every minute
    window.addEventListener('focus', fetchVersion)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('focus', fetchVersion)
    if (interval) clearInterval(interval)
  })
}
