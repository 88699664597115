import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/customer',
    name: 'customers.index',
    component: () => import('./pages/CustomersIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/customers/create',
    alias: '/customer/add',
    name: 'customers.create',
    component: () => import('./pages/CustomersCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/customers/:id',
    alias: '/customer/:id', // Alias for the old route
    name: 'customers.edit',
    component: () => import('./pages/CustomersEdit.vue'),
    meta: { auth: true },
  },

  {
    path: '/workers',
    alias: '/worker', // Alias for the old route
    name: 'workers.index',
    component: () => import('./pages/WorkersIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/employees',
    alias: '/employee', // Alias for the old route
    name: 'employees.index',
    component: () => import('./pages/EmployeesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/guests',
    name: 'guests.index',
    component: () => import('./pages/GuestsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: () => import('./pages/UsersCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/users/:id',
    alias: '/user/:id', // Alias for the old route
    name: 'users.edit',
    component: () => import('./pages/UsersEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/users/merge',
    name: 'users.merge',
    component: () => import('./pages/UsersMerge.vue'),
    meta: { auth: true },
  },
  {
    path: '/company-phones',
    name: 'company-phones.index',
    component: () => import('./pages/CompanyPhonesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/create-company-phone',
    name: 'company-phones.create',
    component: () => import('./pages/CompanyPhonesCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/company-phones/:id',
    name: 'company-phones.edit',
    component: () => import('./pages/CompanyPhonesEdit.vue'),
    meta: { auth: true },
    props: true,
  },

  {
    path: '/customers/:id/hourrecords',
    name: 'hourrecords.customer',
    component: () => import('./pages/HourrecordsCustomer.vue'),
    meta: { auth: true },
    props: true,
  },
  {
    path: '/hourrecords',
    name: 'hourrecords.index',
    component: () => import('./pages/HourrecordsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/hourrecords/:year/:week',
    name: 'hourrecords.details',
    component: () => import('./pages/HourrecordsDetails.vue'),
    props: (route) => ({ edit: route.query.edit === 'true' }),
    meta: { auth: true },
  },

  {
    path: '/family-allowances',
    name: 'FamilyAllowances',
    component: () => import('./pages/FamilyAllowancesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/absences',
    name: 'absences.index',
    component: () => import('./pages/AbsencePlanner.vue'),
    meta: { auth: true },
  },

  {
    path: '/arrival-departure-manager',
    name: 'working-contracts.index',
    component: () => import('./pages/WorkingContractsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/ferien-und-ueberstunden',
    name: 'holidays-and-overtime.index',
    component: () => import('./pages/HolidaysAndOvertime.vue'),
    meta: { auth: true },
  },
]
