import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('./pages/Home.vue'),
    meta: { auth: true },
  },

  {
    path: '/evaluation',
    name: 'evaluation.index',
    component: () => import('./pages/EvaluationPage.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal',
    name: 'cp.index',
    component: () => import('./pages/CpLandingPage.vue'),
    meta: { auth: true },
  },
  {
    path: '/kundenportal/kontakt',
    name: 'contact',
    component: () => import('./pages/CpContact.vue'),
    meta: { auth: true },
  },
  {
    path: '/files',
    name: 'files.index',
    component: () => import('./pages/DropboxEmbed.vue'),
    meta: { auth: true },
  },
  {
    path: '/filemanager',
    name: 'filemanager.index',
    component: () => import('./pages/FileManager.vue'),
    meta: { auth: true },
  },
  {
    path: '/playground/combobox',
    name: 'playground.combobox',
    component: () => import('./pages/PlaygroundCombobox.vue'),
    meta: { auth: true },
  },
  {
    path: '/playground/select',
    name: 'playground.select',
    component: () => import('./pages/PlaygroundSelect.vue'),
    meta: { auth: true },
  },
  {
    path: '/playground',
    name: 'playground.index',
    component: () => import('./pages/PlaygroundIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('./pages/Dashboard.vue'),
    props: true,
    meta: { auth: true },
  },

  {
    path: '/release-notes',
    name: 'ReleaseNotes',
    component: () => import('./pages/ReleaseNotes.vue'),
    meta: { auth: true },
  },

  {
    path: '/linksammlung',
    name: 'LinkCollection',
    component: () => import('./pages/LinkCollection.vue'),
    meta: { auth: true },
  },
  {
    path: '/server-error',
    name: 'ServerError',
    component: () => import('./pages/ErrorPage.vue'),
    props: { code: 500 },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('./pages/ErrorPage.vue'),
    props: { code: 404 },
  },
]
