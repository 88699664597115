const EVALUATION_INPUT_TYPES = {
  DATE_PICKER: 'datePicker',
  MONTH_PICKER: 'monthPicker',
  YEAR_PICKER: 'yearPicker',
  MONTH_OR_YEAR_PICKER: 'monthOrYearPicker',
  RANGE_PIKER: 'rangePicker',
  COMBOBOX: 'combobox',
  SWITCH: 'switch',
  RADIO_GROUP: 'radioGroup',
  SELECT_USER: 'selectUser',
  CUSTOM_COMPONENT: 'customComponent',
}

const COLORS = {
  PRIMARY: '#23b249',
  SECONDARY: '#26a69a',
  ACCENT: '#26a69a',
}

const ProjectState = {
  Pending: 'pending',
  Accepted: 'accepted',
  Rejected: 'rejected',
}

export { EVALUATION_INPUT_TYPES, COLORS, ProjectState }
