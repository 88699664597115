import posthog from 'posthog-js'

export default {
  install(app) {
    if (
      import.meta.env.DEV === true ||
      import.meta.env.VITE_DISABLE_POSTHOG === 'true'
    ) {
      return
    }

    app.config.globalProperties.$posthog = posthog.init(
      'phc_SWcLXBJHL0LijXRqGNVfs3tCwyoPZOaMNLPHHl0kgm2',
      {
        api_host: 'https://eu.i.posthog.com',
      }
    )

    app.provide('posthog', posthog)
  },
}
