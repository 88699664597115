import axios from '@/plugins/axios'
import moment from 'moment'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/store/loading'
import { useDefaultStore } from '@/store/default'

export const useProjectStore = defineStore('project', {
  state: () => ({
    allProjects: [],
  }),
  getters: {
    projects: (state) => state.allProjects.filter((c) => !c.deleted_at),
    deletedProjects: (state) => state.allProjects.filter((c) => c.deleted_at),
  },
  mutations: {},
  actions: {
    setProjects(projects) {
      this.allProjects = projects
    },
    addProject(project) {
      this.allProjects.push(project)
    },
    deleteProject(projectId) {
      const project = this.allProjects.find((e) => e.id === projectId)
      const index = this.allProjects.indexOf(project)
      project.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      this.allProjects[index] = project
    },

    fetchProjects() {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({
          projects: true,
        })
        axios
          .get('/projects')
          .then((response) => {
            this.setProjects(response.data)
            resolve(this.projects)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Mitarbeiter.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({
              projects: false,
            })
          })
      })
    },
    updateProject(project) {
      return new Promise((resolve, reject) => {
        axios
          .put(`projects/${project.id}`, project)
          .then(() => {
            const project = this.allProjects.find(
              (c) => c.id === updatedProject.id
            )
            const index = this.allProjects.indexOf(project)
            this.allProjects[index] = updatedProject
            resolve(project)
          })
          .catch((error) => reject(error))
      })
    },
    deleteProject(projectId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`projects/${projectId}`)
          .then(() => {
            this.deleteProject(projectId)
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
  },
})
