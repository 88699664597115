import axios from '@/plugins/axios'
import { ApiService } from '@/api/ApiService'
import type { BaseModel, SoftDeleteModel } from '@/types/models'
import type { Tool } from './ToolService'

export type ResourceGroup = BaseModel & {
  name: string
}

/**
 * Pivot table connecting orders and tools for resources.
 */
export type OrderResourceToolPivot = {
  order_id: bigint
  resource_tool_id: number
  amount: number | null
}

/**
 * Pivot table for resources and tools.
 */
export type ResourceTool = BaseModel & {
  resource_id: number
  tool_id: number
  amount: number
  is_billable: number
  billable_unit: string | null
  price_per_unit: number | null
  tool: Tool
  order_resource_tools?: OrderResourceToolPivot[]
}

export type Resource = SoftDeleteModel & {
  date: string
  has_free: boolean
  worktype_id: number
  resource_planner_day_id: number
  contract_type_id: number | null
  group_id: number | null
  rapport_id: number | null
  customer_id: number | null
  start_time: string | null
  end_time: string | null
  planning_comment: string | null
  submit_comment: string | null
  comment_for_customer: string | null
  internal_planning_comment: string | null
  completed: boolean
  gps_link: string | null
  group: ResourceGroup | null
  customer: Domain.Users.DTO.CustomerData | null
  worktype: Domain.ResourcePlanner.DTO.WorktypeData | null
  orders: Array<Domain.OrderManagement.DTO.OrderData> | null
  rapportdetails: Array<Domain.ResourcePlanner.DTO.RapportdetailData> | null
  planner_entries: Array<Domain.ResourcePlanner.DTO.PlannerEntryData> | null
  cars: Array<Domain.ResourcePlanner.DTO.CarData> | null
  tools: Array<Domain.ResourcePlanner.DTO.ToolData> | null
  resource_tools?: ResourceTool[] | null
  employees: Array<Domain.Users.DTO.EmployeeData> | null
}

class ResourceService extends ApiService {
  public getResources(data?: Record<string, any>) {
    return this.makeRequest<Resource>(
      'getResources',
      ({ cancelToken }) =>
        axios.$post<Resource[]>(`resources/search`, data, {
          cancelToken,
        }),
      { collection: true, cancellable: true }
    )
  }

  public getResource(id: number) {
    return this.makeRequest<Resource>(
      `getResource/${id}`,
      ({ cancelToken }) =>
        axios.$get(`resources/${id}`, {
          cancelToken,
        }),
      { collection: false, cancellable: true }
    )
  }

  public updateResource(original: Resource, data: Partial<Resource>) {
    return this.optimisticUpdate(original, data, (id, data) =>
      this.makeRequest<void>(
        'updateResource',
        () => axios.$put(`resources/${id}`, data),
        {
          saving: true,
        }
      )
    )
  }

  public deleteResource(resourceId: number) {
    return this.makeRequest<void>(
      'deleteResource',
      () => axios.$delete(`resources/${resourceId}`),
      {
        saving: true,
      }
    )
  }

  public addCarToResource(resourceId: number, carId: number) {
    return this.makeRequest<Domain.ResourcePlanner.DTO.CarData>(
      'addCarToResource',
      () => axios.$post(`resources/${resourceId}/cars/${carId}`),
      {
        saving: true,
      }
    )
  }

  public removeCarFromResource(resourceId: number, carId: number) {
    return this.makeRequest<void>(
      'removeCarFromResource',
      () => axios.$delete(`resources/${resourceId}/cars/${carId}`),
      {
        saving: true,
      }
    )
  }

  public addToolToResource(resourceId: number, toolId: number) {
    return this.makeRequest<ResourceTool>(
      'addToolToResource',
      () => axios.$post(`resources/${resourceId}/tools/${toolId}`),
      {
        saving: true,
      }
    )
  }

  public removeToolFromResource(resourceToolId: number) {
    return this.makeRequest<void>(
      'removeToolFromResource',
      () => axios.$delete(`resource-tools/${resourceToolId}`),
      {
        saving: true,
      }
    )
  }

  public updateResourceTool(
    resourceTool: Partial<ResourceTool> & { id: number }
  ) {
    return this.makeRequest<void>(
      'updateResourceTool',
      () => axios.$put(`resource-tools/${resourceTool.id}`, resourceTool),
      {
        saving: true,
      }
    )
  }

  public updateToolPivot(
    resourceId: number,
    toolId: number,
    payload: Record<string, any>
  ) {
    return this.makeRequest<void>(
      'updateToolPivot',
      () => axios.$patch(`resources/${resourceId}/tools/${toolId}`, payload),
      {
        saving: true,
      }
    )
  }

  public updateOrderPosition(
    resource: Resource,
    orders: Domain.OrderManagement.DTO.OrderData[]
  ) {
    return this.optimisticUpdate(resource.orders!, orders, () =>
      this.makeRequest<void>(
        'updateOrderPosition',
        () =>
          axios.$put(`resources/${resource.id}/orders`, {
            orders,
          }),
        {
          saving: true,
        }
      )
    )
  }

  /**
   * If an order has completed resources and no other incomplete resource,
   * the order can be completed on removing from the resource.
   */
  public async checkIfOrdersCanBeCompletedBeforeRemovingFromResource(
    resource: Resource,
    orderIds: string[]
  ) {
    if (!resource.orders?.length) return false

    const { data: completedResources } = await this.getResources({
      'order_id:in': orderIds,
      completed: true,
    })

    if (completedResources.length === 0) return false

    const { data: incompleteResources } = await this.getResources({
      'order_id:in': orderIds,
      completed: false,
    })

    return incompleteResources.filter((r) => r.id !== resource.id).length === 0
  }

  public getMyResources(date: string) {
    return this.makeRequest<Resource>(
      'getMyResources',
      () =>
        axios.$get<Resource[]>(`resources/my-resources`, {
          params: {
            date,
          },
        }),
      { collection: true }
    )
  }
}

export const resourceService = new ResourceService()
