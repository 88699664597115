import '../css/app.scss'
import '../css/html-text.scss'

import './plugins/travelTo'
import { createApp } from 'vue'
import { setupI18n } from './plugins/i18n'
import { createPinia } from 'pinia'
import moment from './plugins/moment'
import App from './App.vue'
import router from './router'
import { useAuthStore } from './store/auth'
import axios from './plugins/axios'
import { registerAlerts } from './plugins/alerts'
import { swipe } from './directives/swipe'
import { ensureWithinPage } from './directives/ensureWithinPage'
import { swal } from './utils/sweetalert'
import filters from './plugins/filters'
import storeMultiple from './mixins/store-multiple'
import { useBreakPoints } from './composables/useBreakPoints'

import posthogPlugin from './plugins/posthog'
import { tooltip } from './directives/tooltip'
import { clickOutside } from './directives/click-outside'
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import sentry from './plugins/sentry'
import { VueQueryPlugin } from '@tanstack/vue-query'

polyfillCountryFlagEmojis()

const app = createApp(App)
  .use(setupI18n())
  .use(createPinia())
  .use(router)
  .use(posthogPlugin)
  .use(sentry)
  .use(VueQueryPlugin)

registerAlerts(app)

app.directive('swipe', swipe)
app.directive('ensure-within-page', ensureWithinPage)
app.directive('tooltip', tooltip)
app.directive('click-outside', clickOutside)

app.mixin(storeMultiple)

app.config.globalProperties.$moment = moment
app.config.globalProperties.$swal = swal

app.config.globalProperties.$filters = filters

app.config.globalProperties.axios = axios

app.config.globalProperties.$breakpoints = () => {
  const breakpoints = useBreakPoints()

  return breakpoints
}

app.config.globalProperties.$auth = {
  ready: () => true,
  check: () => {
    const store = useAuthStore()

    return !!store.user
  },
  user: () => {
    const store = useAuthStore()

    return store.user
  },
}
app.mount('#app')
