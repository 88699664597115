export const routes = [
  {
    path: '/settings',
    name: 'settings.index',
    component: () => import('./pages/SettingsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/warning-categories',
    name: 'settings.warning-categories',
    component: () => import('./pages/SettingsWarningCategories.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/document-types',
    name: 'settings.document-types',
    component: () => import('./pages/SettingsDocumentTypes.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/languages',
    name: 'settings.languages',
    component: () => import('./pages/SettingsLanguages.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/dashboard',
    name: 'settings.dashboard',
    component: () => import('./pages/SettingsDashboard.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/cultures',
    name: 'cultures.index',
    component: () => import('./pages/CulturesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/create-culture',
    name: 'cultures.create',
    component: () => import('./pages/CulturesCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/cultures/:id',
    name: 'cultures.edit',
    component: () => import('./pages/CulturesEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/activities',
    name: 'activities.index',
    component: () => import('./pages/ActivitiesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/create-activity',
    name: 'activities.create',
    component: () => import('./pages/ActivitiesCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/activities/:id',
    name: 'activities.edit',
    component: () => import('./pages/ActivitiesEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/companies',
    name: 'companies.index',
    component: () => import('./pages/CompaniesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/create-company',
    name: 'companies.create',
    component: () => import('./pages/CompaniesCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/companies/:id',
    name: 'companies.edit',
    component: () => import('./pages/CompaniesEdit.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/translations',
    name: 'settings.translations',
    component: () => import('./pages/SettingsTranslations.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/tags',
    name: 'settings.tags',
    component: () => import('./pages/SettingsTags.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/notifications',
    name: 'settings.notifications',
    component: () => import('./pages/SettingsNotifications.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/notification-layouts',
    name: 'settings.notification-layouts',
    component: () => import('./pages/SettingsNotificationLayouts.vue'),
    meta: { auth: true },
  },
  {
    path: '/projekte',
    name: 'projects.index',
    component: () => import('./pages/SettingsProjects.vue'),
    meta: { auth: true },
  },
]
