import { useDefaultStore } from '@/store'
import type { ResponseMeta } from '@/types/api'
import { handleError } from '@/utils/error-handling'
import type { MaybeRefOrGetter } from 'vue'

export async function savingRequest<T extends () => Promise<any>>(callback: T) {
  useDefaultStore().isSaving(true)

  try {
    return await callback()
  } catch (error) {
    handleError(error)
  } finally {
    useDefaultStore().isSaving(false)
  }
}

export async function fetchRequest<T extends () => Promise<any>>(callback: T) {
  try {
    return await callback()
  } catch (error) {
    handleError(error)
  }
}

/**
 * Get the actual data from the response. Useful because not all responses are the same.
 * Some return the data directly in an array, others in an object with a data key.
 *
 * @param response
 * @returns
 */
export function responseData<TData>(
  response: MaybeRefOrGetter<
    { data: TData; meta?: ResponseMeta } | TData | null | undefined
  >
) {
  const responseValue = toValue(response)

  if (!responseValue) {
    return null
  }

  if (Array.isArray(responseValue)) {
    return responseValue
  }

  if (typeof responseValue === 'object' && 'data' in responseValue) {
    return responseValue.data
  }

  return responseValue
}

/**
 * Get the meta data from the response.
 *
 * @param response
 * @returns
 */
export function responseMeta<TData extends Array<any>>(
  response: MaybeRefOrGetter<
    { data: TData; meta?: ResponseMeta } | TData | null | undefined
  >
) {
  const responseValue = toValue(response)
  if (!responseValue) return null

  if ('meta' in responseValue) {
    return responseValue.meta
  }

  return null
}
