import * as Sentry from '@sentry/vue'
import router from '@/router'

console.log('App version', import.meta.env.VITE_APP_VERSION)

export default {
  install(app) {
    if (
      import.meta.env.DEV === true ||
      import.meta.env.VITE_DISABLE_POSTHOG === 'true'
    ) {
      return
    }

    Sentry.init({
      app,
      dsn: 'https://eb9f78a760218206613ee21f6b95e358@o4508048577200128.ingest.de.sentry.io/4508250404749392',
      release: import.meta.env.VITE_APP_VERSION,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
        }),
        Sentry.replayCanvasIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.sessionTimingIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        '.test',
        /^https:\/\/rapport-brunnereichhof\.ch\/api/,
        /^https:\/\/staging\.rapport-brunnereichhof\.ch\/api/,
      ],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,

      ignoreErrors: ['canceled', 'cancelled', 'aborted', 'abort'],

      beforeSend(event) {
        return event
      },
    })
  },
}
