import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/store/loading'
import { useDefaultStore } from '@/store/default'

export const useTimerecordStore = defineStore('timerecord', {
  state: () => ({
    worktypes: [],
  }),
  getters: {
    workerWorktypes: (state) =>
      state.worktypes.filter((w) => w.available_for_workers),
    employeeWorktypes: (state) =>
      state.worktypes.filter((w) => w.available_for_employees),
  },
  actions: {
    fetchWorktypes() {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ worktypes: true })
        axios
          .$post('worktypes/search', {
            is_active: true,
          })
          .then((response) => {
            this.worktypes = response.data
            resolve(this.worktypes)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Leistungsarten.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ worktypes: false })
          })
      })
    },
  },
})
