import axios from 'axios'
import moment from 'moment'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/store/loading'
import { useDefaultStore } from '@/store/default'

export const useWorkerStore = defineStore('worker', {
  state: () => ({
    allWorkers: [],
  }),
  getters: {
    workers: (state) => state.allWorkers.filter((w) => !w.deleted_at),
    deletedWorkers: (state) => state.allWorkers.filter((w) => w.deleted_at),
    activeWorkers: (state) =>
      state.allWorkers.filter((w) => !w.deleted_at && w.is_active),
  },
  actions: {
    setWorkers(workers) {
      this.allWorkers = [...workers]
    },
    addWorker(worker) {
      this.allWorkers.push(worker)
    },
    removeWorker(workerId) {
      const worker = this.allWorkers.find((e) => e.id === workerId)
      const index = this.allWorkers.indexOf(worker)
      worker.deleted_at = moment().format('YYYY-MM-DD HH:mm:ss')
      this.allWorkers[index] = worker
    },

    fetchWorkers(params) {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({ workers: true })
        axios
          .get('/workers', {
            params: {
              all: true,
              ...params,
            },
          })
          .then((response) => {
            this.setWorkers(response.data)
            resolve(this.workers)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Mitarbeiter.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({ workers: false })
          })
      })
    },
    updateWorker(worker) {
      return new Promise((resolve, reject) => {
        axios
          .put(`workers/${worker.id}`, worker)
          .then(() => {
            const worker = this.allWorkers.find(
              (w) => w.id === updatedWorker.id
            )
            const index = this.allWorkers.indexOf(worker)
            this.allWorkers[index] = updatedWorker
            resolve(worker)
          })
          .catch((error) => reject(error))
      })
    },
    deleteWorker(workerId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`workers/${workerId}`)
          .then(() => {
            this.removeWorker(workerId)
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
  },
})
