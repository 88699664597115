import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/store/loading'
import { useDefaultStore } from '@/store/default'

export const useAuthorizationRulesStore = defineStore('authorizationRules', {
  state: () => ({
    authorizationRules: [],
  }),
  actions: {
    setAuthorizationRules(authorizationRules) {
      this.authorizationRules = authorizationRules
    },

    fetchAuthorizationRules() {
      return new Promise((resolve, reject) => {
        useLoadingStore().loading({
          authorizationRules: true,
        })

        axios
          .get('/rules')
          .then((response) => {
            this.authorizationRules = response.data
            resolve(this.authorizationRules)
          })
          .catch((error) => {
            useDefaultStore().error('Fehler beim Laden der Mitarbeiter.')
            reject(error)
          })
          .finally(() => {
            useLoadingStore().loading({
              authorizationRules: false,
            })
          })
      })
    },
  },
})
