import axios from '@/plugins/axios'
import { ApiService } from '@/api/ApiService'
import type { SoftDeleteModel } from '@/types/models'

export type PlannerEntry = SoftDeleteModel & {
  hours: number | null
  is_driver: boolean
  sleeps_in_mobile_home: boolean
  date: string
  added_later: boolean
  resource_id: number
  source_resource_id: number | null
  employee_id: number
  employee: Domain.Users.DTO.EmployeeData | null
  resource: Domain.ResourcePlanner.DTO.ResourceData | null
  source_resource: Domain.ResourcePlanner.DTO.ResourceData | null
}

class PlannerEntryService extends ApiService {
  public async createPlannerEntry(
    resourceId: number,
    payload: Record<string, any>
  ) {
    return this.makeRequest<{
      plannerEntry: PlannerEntry
      rapportdetails: Domain.ResourcePlanner.DTO.RapportdetailData[]
    }>(
      'createPlannerEntry',
      () => axios.$post(`resources/${resourceId}/planner-entries`, payload),
      { collection: false }
    )
  }

  public deletePlannerEntry(plannerEntryId: number) {
    return this.makeRequest<void>(
      'deletePlannerEntry',
      () => axios.$delete<void>(`planner-entries/${plannerEntryId}`),
      { saving: true }
    )
  }

  public updatePlannerEntry(
    original: PlannerEntry,
    data: Partial<PlannerEntry>
  ) {
    return this.optimisticUpdate(original, data, (id, data) =>
      this.makeRequest<void>(
        'updatePlannerEntry',
        () => axios.$patch(`planner-entries/${id}`, data),
        { saving: true }
      )
    )
  }
}

export const plannerEntryService = new PlannerEntryService()
