import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: 'mitarbeitergespraeche',
    name: 'employee-interviews.index',
    component: () => import('./pages/EmployeeInterviewsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: 'mitarbeitergespraece/erfassen',
    name: 'employee-interviews.create',
    component: () => import('./pages/EmployeeInterviewsUpsert.vue'),
    meta: { auth: true },
  },
  {
    path: 'mitarbeitergespraech/:id',
    name: 'employee-interviews.show',
    component: () => import('./pages/EmployeeInterviewsUpsert.vue'),
    meta: { auth: true },
  },
  {
    path: 'mitarbeitergespraech-fragen',
    name: 'employee-interviews.questions.index',
    component: () => import('./pages/InterviewQuestionsIndex.vue'),
    meta: { auth: true },
  },
]
