import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/transaction-types',
    name: 'transaction-types.index',
    component: () => import('./pages/TransactionTypesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/transactions/bulk-create',
    name: 'transactions.bulk-create',
    component: () => import('./pages/TransactionsBulkCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/transactions/create',
    alias: '/employee-transactions',
    name: 'transactions.create',
    component: () => import('./pages/TransactionsCreate.vue'),
    meta: { auth: true },
  },
  {
    path: '/transactions',
    name: 'transactions.index',
    component: () => import('./pages/TransactionsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/saldo-overview',
    redirect: '/transactions/saldo-overview',
  },
  {
    path: '/transactions/saldo-overview',
    name: 'transactions.saldo-overview',
    component: () => import('./pages/TransactionsSaldoOverview.vue'),
    meta: { auth: true },
  },
]
