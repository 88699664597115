import { type DirectiveBinding } from 'vue'

type Props = {
  top: number
  left: number
  width: number
  disableY?: boolean
}

function ajustPosition(
  el: HTMLElement & { resizeObserver?: ResizeObserver },
  binding: DirectiveBinding<Props>
) {
  let { top, left, width } = binding.value
  const { innerHeight, innerWidth, scrollY, scrollX } = window

  // First position the element at the top left corner of the viewport.
  // This makes sure the page doesn't jump around when the element is positioned.
  el.style.top = `${scrollY}px`
  el.style.left = `${scrollX}px`
  el.style.width = `${width}px`
  el.style.maxWidth = `${innerWidth}px`

  // Wait for the element to be rendered and get its height.
  nextTick(() => {
    const { height } = el.getBoundingClientRect()
    let right = left + width
    let bottom = top + height

    if (bottom > innerHeight + scrollY - 10 && !binding.value.disableY) {
      bottom = innerHeight + scrollY - 10
      top = innerHeight + scrollY - height - 10
    }
    if (top < 0) {
      top = 0
    }
    if (left < 0) {
      left = 0
      right = width
    }
    if (right > innerWidth) {
      width = innerWidth - left
    }

    el.style.top = `${top}px`
    el.style.left = `${left}px`
    el.style.width = `${width}px`
  })
}

export const ensureWithinPage = {
  mounted(el: HTMLElement, binding: DirectiveBinding<Props>) {
    ajustPosition(el, binding)
  },

  updated(el: HTMLElement, binding: DirectiveBinding<Props>) {
    ajustPosition(el, binding)
  },
}
