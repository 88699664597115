import axios from '@/plugins/axios'
import { t } from '@/plugins/i18n'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/store/loading'
import { useDefaultStore } from '@/store/default'

export const useCarStore = defineStore('car', {
  state: () => ({
    cars: [],
    filters: {
      search: null,
      is_active: true,
      in_service: null,
      only_trashed: false,
      is_mobile_home: null,
    },
    options: {
      sortBy: ['name'],
      sortDesc: [false],
      perPage: -1,
    },
  }),
  actions: {
    resetFilters() {
      this.filters = {
        search: null,
        is_active: true,
        only_trashed: false,
        is_mobile_home: null,
      }
    },

    addCar(car) {
      this.cars.push(car)
    },
    removeCar(carId) {
      const car = this.cars.find((c) => c.id === carId)
      const index = this.cars.indexOf(car)
      this.cars.splice(index, 1)
    },

    async fetchCars(params) {
      useLoadingStore().loading({ cars: true })

      const { data } = await axios
        .$post('cars/search', {
          ...this.filters,
          ...this.options,
          ...params,
        })
        .catch((error) => {
          useDefaultStore().alert({
            text: t('Fehler beim Laden der Autos'),
            type: 'error',
          })
          throw error
        })
        .finally(() => {
          useLoadingStore().loading({ cars: false })
        })

      this.cars = data
      return this.cars
    },
    async updateCar(car) {
      // const formData = new FormData()
      // for (const key of Object.keys(car)) {
      //   formData.append(key, getValue(car, key))
      // }

      const { data } = await axios.$post(`cars/${car.id}`, car)

      this.fetchCars()
      return data
    },
    async createCar(car) {
      const { data } = await axios.post('cars', car)

      this.fetchCars()
      return data.data
    },
    async deleteCar(carId) {
      await axios.delete(`cars/${carId}`)

      this.removeCar(carId)
    },

    async restoreCar(carId) {
      await axios.post(`cars/${carId}/restore`)

      this.fetchCars()
    },
  },
})
